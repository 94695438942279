.Tipografia_base{
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color:#474946;
}

input,select{
    padding: 14px !important;
    font-family: Inter, sans-serif !important;
    font-weight: 400;
    line-height: 1.5;
    color:#474946;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}